/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import some from "lodash/some"

import Column from "../components/column"
import Container from "../components/container"
import ContentCard from "../components/content-card"
import Hero from "../components/hero"
import Page from "../components/page"
import Row from "../components/row"

import { getValueRange } from "../helpers"

import refreshIcon from "../images/refresh-icon.svg"

const LivingHomesPage = ({ data }) => {
  const fullWidthList = [true, true, true]
  const models = data.allContentfulModelMaster.edges.filter(
    model =>
      model.node.model__variation_ &&
      model.node.model__variation_.length &&
      some(
        model.node.model__variation_,
        home => home.images && home.images.length
      )
  )
  const refreshButtonSize = [20, 20, 40]
  const [cardsLimit, setCardsLimit] = React.useState(10)

  return (
    <Page data={data.contentfulPage}>
      <Container fullWidth={fullWidthList}>
        <Row noGutters={fullWidthList}>
          <Column noGutters={fullWidthList}>
            <Hero
              background={{
                type: "image",
                source: {
                  mobileImage: data.hero.mobileImage,
                  desktopImage: data.hero.desktopImage,
                },
              }}
              body={
                JSON.parse(data.hero.description.description).content[0]
                  .content[0].value
              }
              title={data.hero.title}
              type="secondary"
              textWidth={576}
              verticalAlign="center"
              ctaList={[
                {
                  type: "secondary",
                  text: data.hero.ctaTitle,
                  link: data.hero.ctaDestination.ctaDestination,
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
      <Container>
        <Row customStyle={{ my: 3 }}>
          {models.map((model, index) => {
            const modelImageList = []
            const columnWidth = index % 5 === 4 ? 8 : 4

            model.node.model__variation_.forEach(homeData => {
              if (homeData.images && homeData.images.length) {
                homeData.images.forEach(imageData => {
                  modelImageList.push(imageData)
                })
              }
            })

            return modelImageList.length && index < cardsLimit ? (
              <Column
                size={[12, 12, columnWidth]}
                key={model.node.id}
                customStyle={{ mt: 3, pr: 0 }}
              >
                <ContentCard
                  beds={getValueRange(model.node.model__variation_, "beds")}
                  baths={getValueRange(model.node.model__variation_, "baths")}
                  imageList={modelImageList}
                  squareFootage={getValueRange(
                    model.node.model__variation_,
                    "squareFootage"
                  )}
                  title={model.node.title}
                  titleLink={{
                    to: `/models/${model.node.slug}`,
                    type: "internal",
                  }}
                />
              </Column>
            ) : null
          })}
        </Row>
        <div sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <button
            sx={{
              bg: "transparent",
              border: "none",
              display: cardsLimit < models.length ? "block" : "none",
              p: 0,
            }}
            onClick={() => {
              setCardsLimit(cardsLimit + 10)
            }}
          >
            <img
              sx={{
                display: "block",
                height: refreshButtonSize,
                width: refreshButtonSize,
              }}
              src={refreshIcon}
              alt="Show more models button"
            />
          </button>
        </div>
      </Container>
    </Page>
  )
}

export default LivingHomesPage

export const query = graphql`
  query livingHomesPageQuery {
    contentfulPage(slug: { eq: "livinghomes" }) {
      ...PageData
    }

    allContentfulModelMaster: allContentfulModelMaster(
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          model__variation_ {
            baths
            beds
            squareFootage
            images {
              fluid(maxWidth: 500) {
                ...GatsbyContentfulFluid_withWebp
              }
              id
              file {
                url
              }
              title
            }
          }
        }
      }
    }

    hero: contentfulBasicCard(slug: { eq: "models-hero" }) {
      ctaTitle
      description {
        description
      }
      title
      ctaDestination {
        ctaDestination
      }
      desktopImage {
        file {
          contentType
          url
        }
      }
    }
  }
`
